
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h4e032a9ba1fe5753': html`Je charge ma config depuis: <code>${0}</code>`,
'h4ecc1b3d116a58d7': html`en attente de la définition de mon attribut <code>${0}</code>`,
'hab2fdbba66c93e40': html`chargement en cours de la config depuis <code>${0}</code>`,
's032e92c80863cc5a': str`activité asynchrone en cours`,
's037df8d2f49aa980': str`chargé`,
's6ed675fff1cc77ed': str`Service que je fournis à mes sous-composants:`,
's84ae32ba760890f9': str`Oy, je suis la coquille de l'app!`,
's9e9b04c54923ae13': str`inactif`,
's9f7452dd75d54d31': str`erreur`,
'seac9afef1a00b5e8': str`en attente`,
'sf48d916fa3ea8dd7': str`Langue:`,
'sfa124b47e896b2aa': str`Rapporteur d'activité en attente d'une définition`,
    };
  